/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TensionGuide_viewer$ref: FragmentReference;
declare export opaque type TensionGuide_viewer$fragmentType: TensionGuide_viewer$ref;
export type TensionGuide_viewer = {|
  +id: string,
  +databaseId: string,
  +hasCirclesWithGovernanceOn: boolean,
  +routes: {|
    +addActionPath: string
  |},
  +$refType: TensionGuide_viewer$ref,
|};
export type TensionGuide_viewer$data = TensionGuide_viewer;
export type TensionGuide_viewer$key = {
  +$data?: TensionGuide_viewer$data,
  +$fragmentRefs: TensionGuide_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TensionGuide_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "databaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCirclesWithGovernanceOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Routes",
      "kind": "LinkedField",
      "name": "routes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addActionPath",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd6cdedb36c1a5c937ce5e1b2456e2601';

module.exports = node;
