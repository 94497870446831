/**
 * @flow
 * @relayHash 5a11f105f87555c434297c6385961c6e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ActionCompletionCheckbox_action$ref = any;
export type CompleteActionInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  actionId: string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type CompleteActionMutationVariables = {|
  input: CompleteActionInput
|};
export type CompleteActionMutationResponse = {|
  +completeAction: ?{|
    +action: ?{|
      +$fragmentRefs: ActionCompletionCheckbox_action$ref
    |}
  |}
|};
export type CompleteActionMutation = {|
  variables: CompleteActionMutationVariables,
  response: CompleteActionMutationResponse,
|};
*/


/*
mutation CompleteActionMutation(
  $input: CompleteActionInput!
) {
  completeAction(input: $input) {
    action {
      ...ActionCompletionCheckbox_action
      id
    }
  }
}

fragment ActionCompletionCheckbox_action on Action {
  id
  completedAt
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteActionPayload",
        "kind": "LinkedField",
        "name": "completeAction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ActionCompletionCheckbox_action"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompleteActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteActionPayload",
        "kind": "LinkedField",
        "name": "completeAction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "5a11f105f87555c434297c6385961c6e",
    "metadata": {},
    "name": "CompleteActionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24bd1e963dbe3bc5c9f6612457e225dc';

module.exports = node;
