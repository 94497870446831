/**
 * @flow
 * @relayHash 2ac8c8cb1c3b19a3ab7025438ae65765
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddActionInput = {|
  clientMutationId?: ?string,
  context: MutationContext,
  circleId: string,
  description: string,
  note?: ?string,
  parentGoalOrTarget?: ?string,
  personId?: ?string,
  privateToCircle: boolean,
  projectId?: ?string,
  roleId?: ?string,
  tagNames?: ?$ReadOnlyArray<string>,
  tensionId?: ?string,
  trigger: boolean,
  triggerEvent?: ?string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type AddActionMutationVariables = {|
  input: AddActionInput
|};
export type AddActionMutationResponse = {|
  +addAction: ?{|
    +actionEdge: ?{|
      +node: ?{|
        +databaseId: string,
        +tension: ?{|
          +processed: boolean
        |},
      |}
    |}
  |}
|};
export type AddActionMutation = {|
  variables: AddActionMutationVariables,
  response: AddActionMutationResponse,
|};
*/


/*
mutation AddActionMutation(
  $input: AddActionInput!
) {
  addAction(input: $input) {
    actionEdge {
      node {
        databaseId
        tension {
          processed
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processed",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddActionPayload",
        "kind": "LinkedField",
        "name": "addAction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ActionEdge",
            "kind": "LinkedField",
            "name": "actionEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Action",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tension",
                    "kind": "LinkedField",
                    "name": "tension",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddActionPayload",
        "kind": "LinkedField",
        "name": "addAction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ActionEdge",
            "kind": "LinkedField",
            "name": "actionEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Action",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tension",
                    "kind": "LinkedField",
                    "name": "tension",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "2ac8c8cb1c3b19a3ab7025438ae65765",
    "metadata": {},
    "name": "AddActionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd428d6171430b7c1d1345dc2fdb8e23';

module.exports = node;
