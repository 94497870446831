/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AgendaProcessModal_tension$ref = any;
type ProjectProcessModal_tension$ref = any;
type TensionProcessModal_tension$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProcessingModals_tension$ref: FragmentReference;
declare export opaque type ProcessingModals_tension$fragmentType: ProcessingModals_tension$ref;
export type ProcessingModals_tension = {|
  +databaseId: string,
  +proposal: ?{|
    +databaseId: string
  |},
  +$fragmentRefs: TensionProcessModal_tension$ref & ProjectProcessModal_tension$ref & AgendaProcessModal_tension$ref,
  +$refType: ProcessingModals_tension$ref,
|};
export type ProcessingModals_tension$data = ProcessingModals_tension;
export type ProcessingModals_tension$key = {
  +$data?: ProcessingModals_tension$data,
  +$fragmentRefs: ProcessingModals_tension$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProcessingModals_tension",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Proposal",
      "kind": "LinkedField",
      "name": "proposal",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TensionProcessModal_tension"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectProcessModal_tension"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgendaProcessModal_tension"
    }
  ],
  "type": "Tension",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f2f11347cfd041708cc9f44c40f3bece';

module.exports = node;
