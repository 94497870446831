// @flow
import {graphql} from 'react-relay'

import type {ID} from '../types'
import createMutationPromise from '../createMutationPromise'

const mutation = graphql`
  mutation UncompleteActionMutation(
    $input: UncompleteActionInput!
  ) {
    uncompleteAction(input: $input) {
      action {
        ...ActionCompletionCheckbox_action
      }
    }
  }
`

export default (actionId: ID): Promise<mixed> => {
  const variables = {
    input: {
      actionId,
    },
  }

  return createMutationPromise({
    mutation,
    variables,
  })
}
