// @flow
import React, {type Node, useEffect, useMemo, useCallback} from 'react'
import {commitLocalUpdate, useFragment, graphql} from 'react-relay'

import {Form, useFormik, Field, FormikProvider} from 'formik'
import classNames from 'classnames'

import environment from 'environment'
import constants from 'utils/projects/constants'
import liftNodes from 'utils/GraphQL/typedLiftNodes'
import checkImpossibleCase from 'utils/MiscUtils/checkImpossibleCase'
import useCKRolesFeed from 'utils/hooks/useCKRolesFeed'
import InputWithRoleAutocomplete from 'components/ui/forms/InputWithRoleAutocomplete'
import Input from 'components/ui/forms/Input'
import Checkbox from 'components/ui/forms/Checkbox'
import Select from 'components/ui/forms/Select'
import WYSIWYGTextareaWithAiSuggest from 'components/ai/AiSuggest/WYSIWYGTextareaWithAiSuggest'
import PeopleSelector from 'components/people/PeopleSelector'
import RoleSelector from 'components/roles/RoleSelector'
import {statusOptions} from 'components/projects/ProjectEditForm'
import {statuses} from 'components/projects/CreateProjectModal'
import OutputTypeSelector, {type OutputType} from './OutputTypeSelector'

import type {
  OutputForm_circle$key as CircleKey,
} from './__generated__/OutputForm_circle.graphql'
import type {
  OutputForm_output$key as OutputKey,
} from './__generated__/OutputForm_output.graphql'
import type {
  OutputForm_meeting$key as MeetingKey,
} from './__generated__/OutputForm_meeting.graphql'

export type Values = $ReadOnly<{
  description: string,
  outputType: OutputType,
  privateToCircle: boolean,
  triggerEvent: string | null,
  personId: string | null,
  roleId: string | null,
  projectStatus: string | null
}>

export type SubmitValues = $ReadOnly<{
  ...Values,
  personType: 'PERSON' | 'EACH_MEMBER' | 'NONE',
  roleType: 'ROLE' | 'INDIVIDUAL_INITIATIVE',
}>

type Props = $ReadOnly<{
  meeting: MeetingKey,
  output: OutputKey | null,
  circle: CircleKey,
  submit: (SubmitValues) => Promise<void>,
  onSuccess: () => void,
}>

const meetingFragment = graphql`
  fragment OutputForm_meeting on TacticalMeeting {
    id
    databaseId

    currentAgendaItem {
      id
      databaseId
    }

    invitedRoles {
      edges {
        node {
          id
        }
      }
    }

    invitedCircles {
      edges {
        node {
          id
        }
      }
    }

    allParticipatingRoles {
      edges {
        node {
          id
          databaseId
          isIndividualAction

          circle {
            id
          }

          assignments {
            edges {
              node {
                person {
                  databaseId

                  ...PeopleSelector_people
                }
              }
            }
          }

          ...RoleSelector_roles
        }
      }
    }

    allParticipatingMembers {
      edges {
        node {
          id
          databaseId

          roles {
            edges {
              node {
                id
                databaseId
                isDisplayedAsCircle
                circle {
                  id
                }

                ...RoleSelector_roles
              }
            }
          }

          ...PeopleSelector_people
        }
      }
    }

    organization {
      databaseId
      isOnV5
      isWysiwygEnabled
    }
  }
`

const outputFragment = graphql`
  fragment OutputForm_output on TacticalOutput {
    type
    description
    privateToCircle
    trigger
    projectStatus

    owner {
      databaseId
    }

    person {
      databaseId
    }
  }
`

const circleFragment = graphql`
  fragment OutputForm_circle on Circle {
    id
    databaseId
    isGovernanceEnabled

    supportedRole {
      id
    }
  }
`

const THREE_COLUMN_OUTPUTS = ['TRIGGER', 'PROJECT']
const TWO_COLUMN_OUTPUTS = ['ACTION']
const ONE_COLUMN_OUTPUT = ['TENSION', 'INFORMATION']

function OutputForm({meeting: meetingKey, output: outputKey, circle: circleKey, submit, onSuccess}: Props): Node {
  const descriptionLabel = (what: OutputType) => {
    switch (what) {
      case 'ACTION':
      case 'TRIGGER':
        return I18n.t('tactical_meetings.react.action_to_do')
      case 'PROJECT':
        return I18n.t('tactical_meetings.react.outcome_to_achieve')
      case 'TENSION':
        return I18n.t('tensions.my_tensions.tension_description')
      case 'INFORMATION':
        return I18n.t('tactical_meetings.react.information_to_share')
      default:
        checkImpossibleCase(what)
        return I18n.t('tactical_meetings.react.action_to_do')
    }
  }

  const eachRoleFillerOption = {
    value: '-2',
    label: I18n.t('forms.each_role_filler'),
  }

  const eachCircleMember = {
    value: '-1',
    label: I18n.t('forms.each_circle_member'),
  }

  const nonePerson = {
    value: 'NONE',
    label: I18n.t('forms.select_person'),
  }

  const meeting = useFragment(meetingFragment, meetingKey)
  const output = useFragment(outputFragment, outputKey)
  const circle = useFragment(circleFragment, circleKey)

  const roles = liftNodes(meeting.allParticipatingRoles)
  const people = liftNodes(meeting.allParticipatingMembers)

  const initialProjectStatus = 'Current'

  const form = useFormik<Values>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      outputType: output?.type || 'ACTION',
      description: output?.description || '',
      privateToCircle: output?.privateToCircle || false,
      triggerEvent: output?.trigger || null,
      personId: output?.person?.databaseId || nonePerson.value,
      roleId: output?.owner?.databaseId || constants.individualActionId,
      projectStatus: output?.projectStatus || initialProjectStatus,
    },
    validate: (values) => {
      const errors = {}

      if (!values.description || !values.description.length)
        errors.description = I18n.t('tactical_meetings.react.description_may_not_be_blank')

      const triggerEventEmpty = !values.triggerEvent || !values.triggerEvent.length
      if (triggerEventEmpty && values.outputType === 'TRIGGER')
        errors.triggerEvent = I18n.t('tactical_meetings.react.triggers_must_have_a_date')

      if (values.outputType !== 'INFORMATION'
          && values.personId === nonePerson.value
          && values.roleId === constants.individualActionId) {
        const message = meeting.organization.isOnV5
          ? I18n.t('tactical_meetings.react.v5.individual_action_must_be_owned')
          : I18n.t('tactical_meetings.react.individual_action_must_be_owned')

        errors.personId = message
      }

      if (values.personId === nonePerson.value && values.outputType === 'TENSION')
        errors.personId = I18n.t('tactical_meetings.react.tension_must_be_owned')

      return errors
    },
    onSubmit: (values) => {
      const role = roles.find(({databaseId}) => values.roleId === databaseId)

      const roleType = role ? 'ROLE' : 'INDIVIDUAL_INITIATIVE'
      const person = people.find(({databaseId}) => values.personId === databaseId)

      let personType

      if (person)
        personType = 'PERSON'
      else if (!person && values.personId === eachRoleFillerOption.value)
        personType = 'EACH_MEMBER'
      else if (!person && values.personId === eachCircleMember.value)
        personType = 'EACH_MEMBER'
      else
        personType = 'NONE'

      submit({
        ...values,
        personType,
        personId: person?.id || null,
        roleType,
        roleId: role?.id || null,
      }).then(() => {
        form.resetForm()
        onSuccess()
      })
    },
  })

  useEffect(() => {
    if (output)
      return

    commitLocalUpdate(environment, (store) => {
      const meetingRecord = store.get(meeting.id)
      if (meetingRecord)
        meetingRecord.setValue(form.dirty, 'hasDraftOutput')
    })
  }, [output, form.dirty, meeting.id])

  const outputType = form.values.outputType

  const personInputContainer = classNames({
    'col-lg-4': THREE_COLUMN_OUTPUTS.includes(outputType),
    'col-lg-6': TWO_COLUMN_OUTPUTS.includes(outputType),
    'col-lg-12': ONE_COLUMN_OUTPUT.includes(outputType),
  })

  const roleInputContainer = classNames({
    'col-lg-4': THREE_COLUMN_OUTPUTS.includes(outputType),
    'col-lg-6': TWO_COLUMN_OUTPUTS.includes(outputType),
  })

  const pickedRole = useMemo(
    () => roles.find((role) => role.databaseId === form.values.roleId) || null,
    [roles, form.values.roleId],
  )

  const pickedPerson = useMemo(
    () => people.find((person) => person.databaseId === form.values.personId) || null,
    [people, form.values.personId],
  )

  const invitedRoleIds = useMemo(
    () => liftNodes(meeting.invitedRoles).map((r) => r.id),
    [meeting.invitedRoles],
  )

  const invitedCircleIds = useMemo(
    () => [circle.id, ...liftNodes(meeting.invitedCircles).map((c) => c.id)],
    [circle.id, meeting.invitedCircles],
  )

  const byInvitees = useCallback(
    (role) => invitedCircleIds.some((cId) => role.circle?.id === cId)
    || invitedRoleIds.some((rId) => role.id === rId)
    || role.id === circle.supportedRole?.id,
    [invitedCircleIds, invitedRoleIds, circle.supportedRole?.id],
  )

  const peopleToSelectMemo = useMemo(() => {
    if (!pickedRole)
      return people

    return pickedRole.isIndividualAction
      ? people
      : liftNodes(pickedRole.assignments).map((a) => a.person)
  }, [pickedRole, people])

  const rolesToSelectMemo = useMemo(
    () => (pickedPerson
      ? liftNodes(pickedPerson.roles).filter(byInvitees)
      : roles),
    [pickedPerson, roles, byInvitees],
  )

  const errors = Object.values(form.errors).map(String)

  const rolesFeed = useCKRolesFeed(meeting.organization.databaseId)

  const promptKey = (() => {
    switch (form.values.outputType) {
      case 'ACTION':
        return 'suggest_tactical_action'
      case 'TRIGGER':
        return 'suggest_tactical_trigger'
      case 'PROJECT':
        return 'suggest_tactical_project'
      case 'TENSION':
        return 'suggest_tactical_gov_tension'
      default:
        return null
    }
  })()

  const aiSuggestProps = (promptKey && meeting.currentAgendaItem?.databaseId) ? {
    promptKey,
    context: {
      type: 'agenda_item',
      id: meeting.currentAgendaItem.databaseId,
    },
    data: {
      meeting: {
        ref_type: 'Meeting',
        ref_id: meeting.databaseId,
      },
    },
  } : undefined

  const wysiwygDescriptionInput = () => (
    <Field
      name="description"
      as={WYSIWYGTextareaWithAiSuggest}
      organization={meeting.organization}
      mode="inline"
      getFeed={rolesFeed}
      fallbackComponent={InputWithRoleAutocomplete}
      fallbackProps={{
        orgDatabaseId: meeting.organization.databaseId,
        input: Input,
      }}
      aiSuggestProps={aiSuggestProps}
      label={descriptionLabel(form.values.outputType)}
    />
  )

  return (
    <div
      test-id="triage-new-output"
      className="tactical-item item"
    >
      <FormikProvider value={form}>
        <Form className="w-100">
          {errors[0] && (
            <div className="alert alert-danger">
              {errors[0]}
            </div>
          )}
          <div className="row">
            <div className="col-lg-3">
              <OutputTypeSelector
                formikProps={form}
                governanceEnabled={circle.isGovernanceEnabled}
              />
            </div>
            <div className="col-lg-9">
              {wysiwygDescriptionInput()}
            </div>
          </div>
          <div className="row">
            {!['TENSION', 'INFORMATION'].includes(form.values.outputType) && (
              <div className={roleInputContainer}>
                <Field
                  as={RoleSelector}
                  useIndividualActions
                  test-id="tactical-output-role"
                  name="roleId"
                  useAsValue="databaseId"
                  label={I18n.t('shared.role')}
                  roles={rolesToSelectMemo}
                />
              </div>
            )}
            {form.values.outputType !== 'INFORMATION' && (
              <div className={personInputContainer}>
                <Field
                  as={PeopleSelector}
                  formikProps={form}
                  clearable
                  nonePerson={nonePerson}
                  eachRoleFiller={pickedRole ? eachRoleFillerOption : null}
                  eachCircleMember={pickedRole || form.values.outputType === 'TENSION' ? null : eachCircleMember}
                  test-id="tactical-output-person"
                  name="personId"
                  useAsValue="databaseId"
                  label={I18n.t('shared.person')}
                  people={peopleToSelectMemo}
                />
              </div>
            )}
            {form.values.outputType === 'TRIGGER' && (
              <div className="col-lg-4">
                <div className="form-group">
                  <Field
                    as={InputWithRoleAutocomplete}
                    orgDatabaseId={meeting.organization.databaseId}
                    input={Input}
                    name="triggerEvent"
                    test-id="tactical-output-trigger-event"
                    label={I18n.t('tactical_meetings.handlebars.trigger_date')}
                  />
                </div>
              </div>
            )}
            {form.values.outputType === 'PROJECT' && (
              <div className="col-lg-4">
                <Field
                  as={Select}
                  name="projectStatus"
                  options={statusOptions(statuses)}
                  initialValue={initialProjectStatus}
                  test-id="tactical-output-project-status-selector"
                  label={I18n.t('projects.my_projects.status')}
                />
              </div>
            )}
          </div>
          <div className="row mb-3">
            <div className="col-lg-12 d-flex justify-content-between">
              <div className="form-check test-field-private mb-0">
                {form.values.outputType !== 'TENSION' && (
                  <Field
                    as={Checkbox}
                    name="privateToCircle"
                    label={I18n.t('tactical_meetings.tactical_item.private_to_circle')}
                  />
                )}
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={form.isSubmitting}
                >
                  {I18n.t('shared.save')}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  )
}

OutputForm.defaultProps = {
  onSuccess: () => {},
}

export default OutputForm
