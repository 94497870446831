// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import type {
  AddActionMutationResponse,
  AddActionInput,
} from './__generated__/AddActionMutation.graphql'

const mutation = graphql`
  mutation AddActionMutation(
    $input: AddActionInput!
  ) {
    addAction(input: $input) {
      actionEdge {
        node {
          databaseId

          tension {
            processed
          }
        }
      }
    }
  }
`

export type {AddActionMutationResponse}

export default (
  input: MutationInput<AddActionInput>,
  viewerId: string,
): Promise<AddActionMutationResponse> => {
  const variables = {
    input,
  }

  const configs = [{
    type: 'RANGE_ADD',
    parentID: viewerId,
    edgeName: 'actionEdge',
  }]

  return createMutationPromise({
    mutation,
    variables,
    configs,
  })
}
