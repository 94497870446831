// @flow
import type {Element} from 'react'
import React, {useCallback, useEffect, useRef} from 'react'
import ReactSelect from 'react-select'
import util from 'utils/MiscUtils'

export type Props = $ReadOnly<{
  defaultValue?: Object,
  id: string,
  isMulti?: boolean,
  isSearchable?: boolean,
  isClearable?: boolean,
  name: string,
  options: Array<any>,
  labelWithImage?: boolean,
  wrapperClass?: string,
}>

function RailsSelect({defaultValue, id, isMulti, isSearchable, isClearable, name, options, labelWithImage, wrapperClass}: Props): Element<"div"> {
  const wrapperRef = useRef(null)
  const selectRef = useRef(null)

  // Hack for bubbling 'change' events:
  // focus and blur events naturally bubble from the underlying ReactSelect component.
  // default/native change events do not bubble, so we have to manually (re-) dispatch them with {bubbles: true} here.
  // setting handlers on for these events has tricks:
  // $form.on('change', 'input, select')  -> will not work. the synthetic event comes from a div
  // $form.on('change', 'div') -> will work but will get an event per div that is bubbled through.
  // $form.on('change', 'div.js-slick-auto-save') -> will work correctly, assuming that exactly one div has that class.
  const handleChange = useCallback(() => {
    if (wrapperRef.current)
      wrapperRef.current.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}))
    else
      util.warn('wrapperRef.current is null')
  }, [wrapperRef])

  useEffect(() => {
    if (selectRef.current) {
      const availableIds = options.map((option) => option.value)
      const selectedIds = selectRef.current.getValue().map((option) => option.value)

      if (options.length === 1)
        selectRef.current.setValue(options)
      else if (selectedIds.filter((optionId) => availableIds.includes(optionId)).length === 0)
        selectRef.current.setValue(null)
    }
  }, [selectRef, options])

  const formatOptionLabel = (option) => (
    <span className="rails_select--option">
      {labelWithImage && option.image
          && (
          <span className="rails_select--option-img">
            <img src={option.image} alt="" />
          </span>
          )
      }
      <span className="rails_select--option-title">{option.label}</span>
      {option.subtitle && <div className="rails_select--option-subtitle">{option.subtitle}</div>}
    </span>
  )

  // Note that this allows searching by subtitle as well
  // It could probably be setup such that you could pass in searchable data fields if desired
  const filterOption = (option, inputValue) => {
    const searchValue = inputValue.toLowerCase()
    const {label, data} = option
    const subtitle = data?.subtitle
    return (
      label.toLowerCase().includes(searchValue)
        || (subtitle?.toLowerCase().includes(searchValue))
    )
  }

  return (
    <div ref={wrapperRef} className={wrapperClass} test-id={`RailsSelect-${name}`}>
      <ReactSelect
        defaultValue={defaultValue}
        id={id}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={isClearable}
        name={name}
        onChange={handleChange}
        options={options}
        classNamePrefix="rails_select"
        formatOptionLabel={formatOptionLabel}
        filterOption={filterOption}
        // $FlowFixMe[incompatible-type]
        ref={selectRef}
        // defaultMenuIsOpen // Uncomment to open the dropdown by default to inspect things in console
      />
    </div>
  )
}

export default RailsSelect
