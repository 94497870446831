import React, {useState, useEffect} from 'react'
import ReactDatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker'
import {enUS, arSA, fr, es, de, hi, ja, it, nl, pl, pt, ru, zhCN} from 'date-fns/locale'
import classNames from 'classnames'
import convertPosixToLdml from 'utils/MiscUtils/convertPosixToLdml'
import moment from 'moment-timezone'

const locales = {
  en: enUS,
  ar: arSA,
  fr,
  es,
  de,
  hi,
  ja,
  it,
  nl,
  pl,
  pt,
  ru,
  'zh-CN': zhCN,
}

type Props = {|
  name: ?string,
  dateString: string,
  className: ?string,
  placeholderText?: ?string,
  disabled?: boolean,
|}

function RailsDatePicker({name, dateString, className, placeholderText, disabled}: Props) {
  const timeZone = gf.app.currentPersonTimeZone() || 'America/New_York'
  const dateFormat = convertPosixToLdml(I18n.t('date.formats.default_dashed'))
  const dateValue = dateString ? moment.tz(dateString, timeZone).toDate() : null
  const [date, setDate] = useState(dateValue)
  const [formattedDate, setFormattedDate] = useState(dateString)
  const locale = I18n.locale || 'en'

  useEffect(() => {
    if (locales[locale]) {
      registerLocale(locale, locales[locale])
      setDefaultLocale(locale)
    }
  }, [locale])

  const handleDateChange = (selectedDate) => {
    if (selectedDate) {
      const formatted = moment(selectedDate).tz(timeZone).format('MMMM D HH:mm:ss YYYY z')
      setDate(selectedDate)
      setFormattedDate(formatted)
    } else {
      setDate(null)
      setFormattedDate('')
    }
  }

  const disabledInput = () => {
    const inputClassName = classNames(className, 'disabled w-100')
    return (
      <input
        className={inputClassName}
        placeholder={placeholderText}
        disabled
      />
    )
  }

  if (disabled?.toString() === 'true')
    return disabledInput()

  return (
    <React.Fragment>
      <ReactDatePicker
        className={className}
        wrapperClassName="w-100"
        dateFormat={dateFormat}
        name={name}
        placeholderText={placeholderText}
        onChange={handleDateChange}
        selected={date}
        locale={locale}
      />
      <input type="hidden" name={name} value={formattedDate || ''} />
    </React.Fragment>
  )
}

export default RailsDatePicker
