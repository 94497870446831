// @flow
import {graphql} from 'react-relay'

import createMutationPromise, {type MutationInput} from '../createMutationPromise'
import type {
  UpdateActionDescriptionMutationResponse as Response,
  UpdateActionInput as Input,
} from './__generated__/UpdateActionDescriptionMutation.graphql'

const mutation = graphql`
  mutation UpdateActionDescriptionMutation(
  $input: UpdateActionInput!
  ) {
    updateAction(input: $input) {
      action {
        id
        description(format: HTML)
        rawDescription: description(format: PLAIN)
        isUpdating
        viewerCanUpdate
      }
    }
  }
`

const UpdateActionDescriptionMutation = (
  {actionId, ...inputs}: MutationInput<Input>,
): Promise<Response> => {
  const variables = {
    input: {
      actionId,
      privateToCircle: false,
      ...inputs,
    },
  }

  const optimisticResponse = {
    updateAction: {
      action: {
        id: actionId,
        description: inputs.description,
        rawDescription: inputs.description,
        viewerCanUpdate: true,
        isUpdating: true,
      },
    },
  }

  return createMutationPromise({
    mutation,
    variables,
    optimisticResponse,
  })
}

export default UpdateActionDescriptionMutation
