// @flow
import type {ComponentType, Node} from 'react'
import React, {lazy, useState, Suspense, useRef, useCallback} from 'react'
import LoadingDots from 'components/ui/LoadingDots'
import util from 'utils/MiscUtils'
import styles from './index.scss'

const WYSIWYGTextarea: ComponentType<*> | string = lazy(() => import(
  /* webpackChunkName: "WYSIWYGTextarea" */
  '../WYSIWYGTextarea'
))

export type CKAutocompleteItem = $ReadOnly<{
  link: string,
  name: string,
  id: string,
}>;

export type Props = $ReadOnly<{
  autofocus?: boolean,
  mode?: 'classic' | 'inline',
  buttonSet?: 'font-styles-only'| 'basic' | 'full',
  fieldName: string,
  fieldValue: string,
  rows?: number,
  placeholder?: string,
  getFeed?: (string) => $ReadOnlyArray<CKAutocompleteItem>,
}>

function RailsWYSIWYG({
  autofocus,
  mode,
  buttonSet,
  fieldName,
  fieldValue,
  rows,
  placeholder,
  getFeed,
}: Props): null | Node {
  const [newValue, setNewValue] = useState(fieldValue || '')
  const inputRef = useRef(null)

  const handleChange = useCallback((e) => {
    setNewValue(e.target.value)
    if (inputRef.current)
      inputRef.current.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}))
  }, [inputRef])

  const handleFocus = useCallback(() => {
    if (inputRef.current)
      inputRef.current.dispatchEvent(new Event('focus'))
    else
      util.warn('wrapperRef.current is null')
  }, [inputRef])

  const handleBlur = useCallback(() => {
    if (inputRef.current)
      inputRef.current.dispatchEvent(new Event('blur'))
    else
      util.warn('wrapperRef.current is null')
  }, [inputRef])

  return (
    <div test-id={`RailsWYSIWYG-${fieldName}`}>
      <Suspense fallback={<LoadingDots />}>
        <WYSIWYGTextarea
          autofocus={autofocus}
          mode={mode}
          buttonSet={buttonSet}
          name={fieldName}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          rows={rows}
          value={fieldValue}
          placeholder={placeholder}
          getFeed={getFeed || (() => [])}
          tinyMarginBottom
        />
      </Suspense>
      <textarea
        className={styles.hidden}
        name={fieldName}
        readOnly
        value={newValue}
        ref={inputRef}
      />
    </div>
  )
}

RailsWYSIWYG.defaultProps = {
  autofocus: false,
  mode: 'classic',
  placeholder: '',
  rows: 5,
}

export default RailsWYSIWYG
