// expose any components referenced directly from server side
import ReactSelect from 'react-select'

import ExternalLinkAlt from 'components/ui/icons/fa/ExternalLinkAlt'
import ProjectsTab from 'components/profile/ProjectsTab'
import ChecklistsTab from 'components/profile/ChecklistsTab'
import MetricsTab from 'components/profile/MetricsTab'
import GoalsTab from 'components/profile/GoalsTab'
import MeetingRelayWrapper from 'components/meetings/RelayWrapper'
import TacticalMeetingChecklistItems from 'components/pages/TacticalMeeting/TacticalMeetingView/Steps/TacticalMeetingChecklistItems'
import TacticalMeetingMetrics from 'components/pages/TacticalMeeting/TacticalMeetingView/Steps/TacticalMeetingMetrics'
import SideBar from './SideBar'
import SearchBox from './SearchBox'
import SupportingProjectsList from './goals/SupportingProjectsList'
import AsyncBanners from './banners/AsyncBanners'
import OfflineBanner from './banners/OfflineBanner'
import SetupModeBannerContainer from './banners/SetupModeBannerContainer'
import FlashNotifications from './banners/FlashNotifications'
import AiChat from './ai/AiChat'
import InputWithAiSuggest from './ai/AiSuggest/InputWithAiSuggest'

import AppLink from './ui/AppLink'
import Age from './ui/Age'
import BadgeWithTooltip from './ui/BadgeWithTooltip'
import CheckboxOutline from './ui/icons/md/CheckboxOutline'
import FrogTipBox from './ui/FrogTipBox'
import DefinitionTooltip from './ui/DefinitionTooltip'
import PremiumCallout from './ui/PremiumCallout'
import Download from './ui/icons/fa/Download'
import HammerAndWrench from './ui/icons/HammerAndWrench'
import HolacracyMark from './ui/icons/HolacracyMark'
import HolacracyMarkBlue from './ui/icons/HolacracyMarkBlue'
import NoItemsMessage from './ui/NoItemsMessage'
import NoteIcon from './ui/icons/md/Note'
import ToggleOn from './ui/icons/md/ToggleOn'
import ToggleOff from './ui/icons/md/ToggleOff'
import RailsSelect from './ui/forms/RailsSelect'
import RailsDatePicker from './ui/forms/RailsDatePicker'
import RailsSubmitButton from './ui/RailsSubmitButton'
import RailsWYSIWYG from './ui/forms/RailsWYSIWYG'
import AdminFilterPeopleDropdown from './ui/AdminFilterPeopleDropdown'

import ConfigureJiraSection from './integrations/ConfigureJiraSection'
import TensionInfo from './tensions/ui/TensionInfo'

import DefaultTabs from './DefaultTabs'

import TensionProcessContainer from './tensions/TensionProcessContainer'

// Lazy loaded chunks
import PersonTags from './ProfileSettings/Tags/index.lazy'
import TagHistoryPage from './pages/TagHistory/index.lazy'
import TagPage from './pages/Tag/index.lazy'
import OrgNavApp from './OrgNav/OrgNavApp/index.lazy'
import GraphqlExplorer from './GraphqlExplorer/index.lazy'
// ^ Lazy loaded chunks

import GoalsDateRange from './pages/Goals/GoalsDateRange'
import InvitationAcceptedPage from './pages/InvitationAcceptedPage'

import PrintableGovernance from './reports/PrintableGovernance'

import Constitution from './organizationSettings/Constitution'
import OrganizationHistory from './organizationSettings/OrganizationHistory'
import RoleTemplates from './organizationSettings/RoleTemplates'

import About from './ProfileSettings/About'
import AddProjectMenuItem from './projects/AddProjectMenuItem'
import ShepherdTour from './tours/ShepherdTour'

const tours = {
  ShepherdTour,
}

const banners = {
  FlashNotifications,
  AsyncBanners,
  OfflineBanner,
  SetupModeBannerContainer,
}

const meetings = {
  MeetingRelayWrapper,
  TacticalMeetingChecklistItems,
  TacticalMeetingMetrics,
}

const ui = {
  DefinitionTooltip,
  FrogTipBox,
  PremiumCallout,
  BadgeWithTooltip,
  AppLink,
  NoItemsMessage,
  Age,
  RailsDatePicker,
  RailsSelect,
  RailsSubmitButton,
  RailsWYSIWYG,
  ReactSelect,
  AdminFilterPeopleDropdown,
  SearchBox,
}

const ai = {
  AiChat,
  InputWithAiSuggest,
}

const icons = {
  CheckboxOutline,
  Download,
  HammerAndWrench,
  HolacracyMark,
  HolacracyMarkBlue,
  Note: NoteIcon,
  ToggleOff,
  ToggleOn,
  ExternalLinkAlt,
}

const organizationSettings = {
  Constitution,
  RoleTemplates,
  OrganizationHistory,
}

const profileSettings = {
  PersonTags,
  About,
}

const reports = {
  PrintableGovernance,
}

const projects = {
  AddProjectMenuItem,
}

const goals = {
  SupportingProjectsList,
}

export default {
  DefaultTabs,
  TensionInfo,
  banners,
  meetings,
  ai,
  tours,
  goals,
  ConfigureJiraSection,
  OrgNavApp,
  SideBar,
  TagHistoryPage,
  TagPage,
  ui,
  projects,
  icons,
  organizationSettings,
  TensionProcessContainer,
  GraphqlExplorer,
  profileSettings,
  reports,
  InvitationAcceptedPage,
  ProjectsTab,
  ChecklistsTab,
  MetricsTab,
  GoalsTab,
  GoalsDateRange,
}
